<template>
  <footer class="footer">
    <hr class="separator mb-5" />
    <div class="d-flex flex-column">
      <div class="row align-self-center box-width-margin">
        <div class="col-4 p-3 mobile-display">
          <div class="footer-information-container">
            <div class="my-2 flex-item-logo">
              <a href="https://ds360.pl" loading="lazy">
                <img
                  src="@/assets/img/footer/logoDS360.svg"
                  alt="linkedin"
                  class="logo-icon"
                />
              </a>
            </div>
            <div class="my-2 interactive-footer-info flex-item-address">
              <a
                href="https://goo.gl/maps/42fkeExvGkecKrtS7"
                target="_blank"
                loading="lazy"
                class="text-reset text-decoration-none float-md-start xs-for-address"
              >
                <img
                  src="@/assets/img/footer/venue.svg"
                  alt="venue"
                  class="other-icon float-start"
                />
                <span class="d-inline-block ms-3 small">
                  ul. Żurawia 71<br />
                  15-540 Białystok
                </span>
              </a>
            </div>
            <div class="my-2 interactive-footer-info flex-item-email">
              <a
                @click="copyText()"
                loading="lazy"
                class="text-reset text-decoration-none float-md-start xs-for-contact"
              >
                <img
                  src="@/assets/img/footer/mail.svg"
                  alt="mail"
                  class="other-icon"
                />
                <span class="d-inline-block ms-3 small" id="contactEmail"
                  >kontakt@ds360.pl</span
                >
              </a>
            </div>
            <div class="my-2 interactive-footer-info flex-item-social-media">
              <a
                href="https://www.linkedin.com/company/ds360/"
                target="_blank"
                loading="lazy"
                class="text-reset text-decoration-none float-md-start"
              >
                <img
                  src="@/assets/img/footer/linkedin.svg"
                  alt="linkedin"
                  class="other-icon social-icon"
                />
                <span class="d-inline-block ms-3 small">LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
        <div class="contact-form-container p-3">
          <ContactUsForm />
        </div>
      </div>
      <div class="text-center my-4">
        {{ $t("WebFooter.policy") }}
      </div>
    </div>
    <!--
          <div>
            <video v-if="!isSafari()" autoplay muted loop playsinline class="video-footer display-PC" noremoteplayback
              disablepictureinpicture>
              <source src="@/assets/animations/outro_out.webm" type="video/webm" />
            </video>

            <img v-if="isSafari()" class="video-footer display-PC" src="@/assets/animations/ds_outro_safari.png"
              alt="Mountains outro image" loading="lazy" />
          </div>
    -->
  </footer>
  <EmailAlert
    v-if="this.sendTrigger"
    :headline="alertHeader"
    :message="alertMessage"
    @closeAlertBox="closeAlert"
  >
  </EmailAlert>
</template>

<script>
import ContactUsForm from "./contactUsForm/ContactUsForm";
import EmailAlert from "./alerts/EmailAlert.vue";
export default {
  data() {
    return {
      sendTrigger: false,
      alertHeader: "",
      alertMessage: "",
    };
  },

  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    copyText() {
      navigator.clipboard.writeText(
        document.getElementById("contactEmail").innerText
      );
      this.sendTrigger = true;
      this.alertHeader = "Alerts.copy_contact_headline";
      this.alertMessage = "Alerts.copy_contact_message";
    },
    closeAlert(value) {
      this.sendTrigger = value;
    },
  },
  components: {
    ContactUsForm: ContactUsForm,
    EmailAlert: EmailAlert,
  },
};
</script>
