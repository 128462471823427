import { createRouter, createWebHistory } from 'vue-router'
import DS360HomeView from '../views/DS360HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DS360HomeView
    
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    component: () => import('@/views/RecruitmentView.vue')
  }
  ,
  // {
  //   path: '/cryptoapp',
  //   name: 'cryptoapp',
  //   component: () => import('@/views/CryptoAppView.vue')
  // },
  {
    path: "/404",
    name: 'ErrorPage404',
    component: () => import('@/views/ErrorPage404.vue'),
    meta: {
      hideNavbar: true,
     }
    
  },
  {
    path:"/generator",
    name: "generator",
    component: () => import('@/views/EmailGeneratorView.vue')
  },
  {
    path:"/EmailGenerator.html",
    name: "emailgenerator",
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    var additional_height=0;
        if(screen.width<1000){
          additional_height = 300;
        }
    // always scroll to top
    if(to.hash){
      return{
        el: to.hash,
        top: 0+additional_height,
        behavior:'smooth'
      }
    }
    else
      return { 
        top: 0,
        behavior: 'auto'
       }
  },
})

export default router
